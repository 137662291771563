/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from "react"
import { EXPERIMENTS as SERVER_EXPERIMENTS, Experiment, ExperimentVariants } from "src/experiments"
import { isMobile } from "src/utils"
import useStorage from "src/lib/use-storage"
import { Locale, Segment } from "src/sanity/types"

const CLIENT_EXPERIMENTS: Experiment[] = [
    // {
    //     name: "qr_code_on_consumer",
    //     variants: ["control", "with_qr_code"],
    //     segments: ["private"],
    //     langs: ["dk", "en"],
    //     deviceTypes: ["desktop"],
    // },


    // {
    //     name: "web_business_onboarding",
    //     variants: {
    //         "web": { path: "", percent: 0.5 },
    //         "app": { path: "" },
    //     },
    //     segments: ["business"],
    //     langs: ["dk", "en"],
    //     deviceTypes: ["desktop"],
    // },

    {
        name: "lbb_lead_overlay_email_label",
        variants: {
            "company": { path: "", percent: 0.5 },
            "generic": { path: "" },
        },
        segments: ["business"],
        langs: ["dk", "en"],
    },
]

type ExperimentsContextType = {
    allExperiments: Experiment[]
    // Experiments that are assigned to the user, and active (on the page)
    activeExperiments: ExperimentVariants
    // Experiments that are assigned to the user, but not necessarily active (on the page)
    assignedExperiments: ExperimentVariants
}

export const ExperimentsContext = createContext<ExperimentsContextType>({
    allExperiments: [],
    activeExperiments: {},
    assignedExperiments: {},
})

export function useExperiments() {
    return useContext(ExperimentsContext)
}
function getServerExperiments(checkPath = true): ExperimentVariants {
    // Get active experiments from cookie
    const activeExperiments = SERVER_EXPERIMENTS.reduce((acc, experiment) => {
        // If host is on .se, then we prefix the path with /se to normalize it
        let clientBrowserPath = global.window?.location.pathname
        clientBrowserPath = global.window?.location.hostname.includes(".se") ? `/se${clientBrowserPath}` : clientBrowserPath
        if (checkPath && experiment.path !== clientBrowserPath)
            return acc

        const cookie = global.window?.document.cookie
        const cookieName = `experiment_${experiment.name}`
        const cookieValue = cookie?.split(";").find((c) => c.trim().startsWith(cookieName))?.split("=")[1]
        if (cookieValue) {
            acc[experiment.name] = {
                activeVariant: decodeURIComponent(cookieValue),
                path: experiment.variants[cookieValue]?.path,
            }
        }
        return acc
    }, {})

    return activeExperiments
}

function getClientExperiments({ segment, lang, getItem, setItem }: { segment: Segment, lang: Locale, getItem: ReturnType<typeof useStorage>["getItem"], setItem: ReturnType<typeof useStorage>["setItem"] }): ExperimentVariants {
    const currentDeviceType = isMobile ? "mobile" : "desktop"
    const activeExperiments = CLIENT_EXPERIMENTS.reduce((acc, experiment) => {
        // Check if experiment should be active on this device
        if (experiment.deviceTypes && !experiment.deviceTypes.includes(currentDeviceType))
            return acc

        // Check if experiment should be active on this segment
        if (experiment.segments && !experiment.segments.includes(segment))
            return acc

        // Check if experiment should be active on this language
        if (experiment.langs && !experiment.langs.includes(lang))
            return acc

        // Check if experiment variant is already determined in storage
        const storageExperimentName = `experiment_${experiment.name}`
        const value = getItem(storageExperimentName, "local")
        if (value) {
            acc[experiment.name] = {
                activeVariant: value,
                path: experiment.variants[value]?.path,
            }
            return acc
        }

        // If experiment is active, determine variant
        const random = Math.random()
        const [activeVariantName, activeVariant] = Object.entries(experiment.variants).find(([, { percent }]) => random < percent || !percent)
        const variant = {
            activeVariant: activeVariantName,
            path: activeVariant.path,
        }

        // Set storage
        setItem(storageExperimentName, variant.activeVariant, "local")

        acc[experiment.name] = variant
        return acc
    }, {})

    return activeExperiments
}

function getAndSetExperiments({ segment, lang, getItem, setItem }): ExperimentsContextType {
    const serverExperiments = getServerExperiments()
    const clientExperiments = getClientExperiments({ segment, lang, getItem, setItem })
    const context: ExperimentsContextType = {
        allExperiments: [...CLIENT_EXPERIMENTS, ...SERVER_EXPERIMENTS],
        activeExperiments: { ...clientExperiments, ...serverExperiments },
        assignedExperiments: { ...getServerExperiments(false) },
    }

    const experiments = Object.entries(context.activeExperiments).reduce((acc, [experimentName, variant]) => {
        acc.push(`${experimentName}=${decodeURIComponent(variant.activeVariant)}`)
        return acc
    }, [])

    const event = {
        event: "experiment",
        experiments: experiments.join(","),
    }

    global.window?._paq?.push(["setCustomDimensionValue", 1, experiments.join(",")])
    global.window?.dataLayer?.push(event)

    return context
}

type ChildrenProp = React.ReactNode | ((experiments: ExperimentsContextType) => React.ReactNode)
export const ExperimentsProvider = ({ children, url, segment, lang }: { children: ChildrenProp, url: string, segment: Segment, lang: Locale }) => {
    // const [path, setPath] = useState(global?.window?.location.pathname)
    const { getItem, setItem } = useStorage()
    const [value, setValue] = useState({ allExperiments: [], activeExperiments: {}, assignedExperiments: {} })

    useEffect(() => {
        setValue(getAndSetExperiments({ segment, lang, getItem, setItem }))
    }, [])
    useEffect(() => {
        setValue(getAndSetExperiments({ segment, lang, getItem, setItem }))
    }, [segment, lang, url])

    return (
        <ExperimentsContext.Provider value={value}>
            {typeof children === "function" ? children(value) : children}
        </ExperimentsContext.Provider>
    )
}
